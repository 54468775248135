import SpinnerContainer from 'common/components/SpinnerContainer';
import { strategiesTable } from 'constants/constants';
import React, { useState, useEffect } from 'react';
import { ContentTableWrapper } from './app.style';
import StrategiesTable from 'common/components/StrategiesTable/StrategiesTable';
import ChainSelector from 'common/components/ChainSelector';
import { MonitoredAmm, MONITORED_AMMS } from 'constants/constants';
import { usePaginatedFarms } from 'common/hooks/usePaginatedFarms';
import { usePaginatedStrategies } from 'common/hooks/usePaginatedStrategies';
import { usePaginatedMonitorVaults } from 'common/hooks/usePaginatedMonitorVaults';

type Props = {
  pausedOnly?: boolean;
};

const StrategiesContainer: React.FC<Props> = (props) => {
  const [tab, setTab] = useState<MonitoredAmm>(MONITORED_AMMS[0].amms[0]);
  const [criterion, setCriterion] = useState<string | undefined>(undefined);
  
  // Use our pagination hooks for all data types
  const { 
    farms, 
    loading: loadingFarms, 
    error: errorFarms,
    refreshAll: refreshFarms
  } = usePaginatedFarms();
  
  const {
    vaults,
    loading: loadingVaults,
    error: errorVaults,
    refreshAll: refreshVaults
  } = usePaginatedMonitorVaults(false);  // We don't need rebalance-only filter here

  const {
    strategies,
    loading: loadingStrategies,
    error: errorStrategies,
    refreshAll: refreshStrategies
  } = usePaginatedStrategies();
  
  // Load all data on initial component mount
  useEffect(() => {
    const loadAllData = async () => {
      // Load all data in parallel
      await Promise.all([
        refreshFarms(),
        refreshVaults(),
        refreshStrategies()
      ]);
    };
    
    loadAllData();
  }, []);

  const table = strategiesTable;

  // Combine all errors to show
  const anyError = errorFarms || errorVaults || errorStrategies;
  if (anyError) {
    console.error(anyError);
  }

  // Check if any data is still loading
  const isLoading = loadingFarms || loadingVaults || loadingStrategies;

  return (
    <SpinnerContainer isLoading={isLoading}>
      <ChainSelector 
        handleClick={(tabName: MonitoredAmm) => setTab(tabName)} 
        currAmm={tab} 
        isHidden={props.pausedOnly}
      />
      <ContentTableWrapper>
        <StrategiesTable 
          table={table} 
          strategies={strategies}
          vaults={vaults}
          farms={farms}
          filter={tab} 
          handleSorting={(sortBy: string | undefined) => setCriterion(sortBy)}
          criterion={criterion} 
          pausedOnly={props.pausedOnly}
        />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default StrategiesContainer;
